import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';

import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import CallToAction from 'components/directus/call-to-action/CallToAction';
import OnlineAdminPortal from 'components/directus/online-admin-portal/OnlineAdminPortal';
import PageTitle from 'components/directus/page-title/PageTitle';
import MainBanner from 'components/directus/main-banner/MainBanner';
import CustomerReviews from 'components/directus/customer-reviews/CustomerReviews';
import { DirectusPageData } from 'interfaces/directus-page-data.interface';
import onlineAdminHook from 'hooks/online-admin-portal/online-admin-portal.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { PageProps } from 'utils/gatsby.interface';

export default ({ data }: PageProps<DirectusPageData>): JSX.Element => {
  const slug = 'online-admin-portal';
  const { node } = getFieldBySlug(slug, data.allDirectusPage.edges);
  const description = onlineAdminHook()
    .find(({ node }) => node.width === 'full')
    .node.description.replace(/- /g, '');
  const steps = description.split('\n');

  return (
    <>
      <Helmet>
        <title>{node.title}</title>
        <meta name="title" content={node.title} />
        <meta name="description" content={node.description} />
        <meta name="robots" content={node.robots} />
        <link rel="canonical" href={node.canonical} />
        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'HowTo',
            name: 'How to Import a Company to Your Online Client Portal - Step-by-Step Guide',
            description:
              'Learn how to add an existing limited company to your Online Client Portal in just a few seconds and manage its accounts, filing, and administrative tasks for free.',
            step: [
              steps.map((step) => ({
                '@type': 'HowToStep',
                text: step,
              })),
            ],
            keywords:
              'Import a Company, Online Client Portal, Limited Company, Rapid Formations, Filing Tasks, Manage Accounts, Registered Office Address, Appoint Directors, Resign Directors, Significant Control, Share Certificates, Address Services, VAT',
          })}
        </script>
      </Helmet>

      <MainBanner slug={slug} />

      <Wrapper>
        <Section bottom={0}>
          <PageTitle slug={slug} />
        </Section>
        <Section>
          <OnlineAdminPortal />
        </Section>
        <Section>
          <CustomerReviews />
        </Section>
        <Section>
          <CallToAction slug="company-formation" />
        </Section>
      </Wrapper>
    </>
  );
};

export const query = graphql`
  query {
    allDirectusPage(filter: { slug: { eq: "online-admin-portal" } }) {
      edges {
        node {
          title
          slug
          description
          robots
          canonical
        }
      }
    }
  }
`;
