import React from 'react';
import slugify from 'react-slugify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/common/button/Button';
import Markdown from 'components/common/markdown/Markdown';
import onlineAdminHook from 'hooks/online-admin-portal/online-admin-portal.hook';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome/solid';

import {
  onlineAdminPortal,
  onlineAdminPortal__full,
  onlineAdminPortal__half,
  onlineAdminPortal__content,
  onlineAdminPortal__halfCircle,
  onlineAdminPortal__half__icon,
  onlineAdminPortal__half__button,
} from './OnlineAdminPortal.scss';

export default (): JSX.Element => {
  const adminPortal = onlineAdminHook();

  return (
    <div className={onlineAdminPortal}>
      {adminPortal.map(({ node }) => (
        <div
          key={`admin-portal-${slugify(node.title)}`}
          className={node.width === 'full' ? onlineAdminPortal__full : onlineAdminPortal__half}
        >
          <div className={onlineAdminPortal__content}>
            {node.fontawesome_icon && (
              <div className={onlineAdminPortal__halfCircle} style={{ backgroundColor: node.icon_color }}>
                <FontAwesomeIcon
                  icon={getSolidFontAwesomeIcon(node.fontawesome_icon)}
                  className={onlineAdminPortal__half__icon}
                />
              </div>
            )}
            <h3>{node.title}</h3>
            <Markdown
              source={node.description}
              container
              listItemIcon={
                <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faChevronRight')} size="sm" aria-label="check icon." />
              }
            />
          </div>
          {node.button_link && (
            <Button to={node.button_link} role={node.button_role} className={onlineAdminPortal__half__button}>
              {node.button_text}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};
